// PLACE ON TOP OF WHATEVER WORD / WHITESPACE WHERE YOU CURRENTLY ARE!
import styled from "styled-components";

const Chord = styled.b`
  bottom: 90%;
  color: blue;
  font-weight: bold;
  position: absolute;
  display: inline-block;
`;

export default Chord;
