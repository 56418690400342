import * as React from "react";
import { Link } from "gatsby";

import Bridge from "../../components/Bridge.js";
import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import Verse from "../../components/Verse.js";

export default function YellowEyes() {
  return (
    <Layout>
      <Helmet title="Yellow Eyes | Rayland Baxter" />

      <h1>Yellow Eyes</h1>

      <h2>Rayland Baxter</h2>

      <h4>Key: A</h4>

      <h4>Tempo: 116</h4>

      <p>Capo: 2</p>

      <Link to="/">List</Link>

      <Columns columns="3">
        <Verse>
          <p>
            There's a <Ch>A</Ch>paperclip resting on my <Ch>D</Ch>countertop
          </p>
          <p>
            Sunday morning <Ch>A</Ch>I forgot
          </p>
          <p>
            What it's like to <Ch>D</Ch>lose a friend
          </p>
        </Verse>
        <Verse>
          <p>
            <Ch>A</Ch>Yesterday
          </p>
          <p>
            How it seems so <Ch>D</Ch>far away
          </p>
          <p>
            And I've said <Ch>A</Ch>all I can say <Ch>D</Ch>
          </p>
        </Verse>
        <Chorus>
          <p>
            But now it's <Ch>A</Ch>time I get to <Ch>D</Ch>goin'
          </p>
          <p>
            But now it's <Ch>A</Ch>time I get to <Ch>D</Ch>find my own way
          </p>
          <p>
            And I'll leave you <Ch>A</Ch>lonely <Ch>D</Ch>
          </p>
          <p>
            I'll leave you <Ch>A</Ch>lonely <Ch>D</Ch>
          </p>
        </Chorus>
        <Verse>
          <p>
            Now I'm <Ch>A</Ch>on the road
          </p>
          <p>
            Knowing not which <Ch>D</Ch>way to go
          </p>
          <p>
            Is it yes or <Ch>A</Ch>is it no?
          </p>
          <p>
            I don't really <Ch>D</Ch>care
          </p>
        </Verse>
        <Verse>
          <p>
            For the <Ch>A</Ch>golden sun
          </p>
          <p>
            Has washed me away and <Ch>D</Ch>I've become
          </p>
          <p>
            Just another <Ch>C#m</Ch>vagabond holding my <Ch>Bm</Ch>head, oh
          </p>
        </Verse>
        <Chorus>
          <p>
            But now it's <Ch>A</Ch>time I get to <Ch>D</Ch>goin'
          </p>
          <p>
            But now it's <Ch>A</Ch>time I get to <Ch>D</Ch>find my own way
          </p>
          <p>
            And I'll leave you <Ch>A</Ch>lonely <Ch>D</Ch>
          </p>
          <p>
            I'll leave you <Ch>A</Ch>lonely <Ch>D</Ch>
          </p>
        </Chorus>
        <Bridge>
          <p>
            <Ch>F#m</Ch>All in all it's a beautiful <Ch>D</Ch>day
          </p>
          <p>
            When I <Ch>F#m</Ch>wake up all alone
          </p>
          <p>
            I cannot <Ch>D</Ch>help but run away
          </p>
          <p>
            Now I'm a<Ch>F#m</Ch>fraid
          </p>
          <p>
            That it's all in my <Ch>D</Ch>head
          </p>
          <p>
            Don't you <Ch>F#m</Ch>know
          </p>
          <p>
            That it's all in your <Ch>D</Ch>head?
          </p>
        </Bridge>
        <Verse>
          <p>
            So you <Ch>A</Ch>roll the dice
          </p>
          <p>
            Melt a fire, <Ch>D</Ch>burn the ice
          </p>
          <p>
            Find the girl with <Ch>A</Ch>yellow eyes
          </p>
          <p>
            And go and break her <Ch>D</Ch>heart
          </p>
        </Verse>
        <Verse>
          <p>
            See that nothing <Ch>A</Ch>changes
          </p>
          <p>
            And I know not what <Ch>D</Ch>I've become
          </p>
          <p>
            A prisoner to <Ch>C#m</Ch>everyone I've loved from the <Ch>Bm</Ch>
            start, oh
          </p>
        </Verse>
        <Chorus>
          <p>
            But now it's <Ch>A</Ch>time I get to <Ch>D</Ch>goin'
          </p>
          <p>
            But now it's <Ch>A</Ch>time I get to <Ch>D</Ch>find my own way
          </p>
          <p>
            And I'll leave you <Ch>A</Ch>lonely <Ch>D</Ch>
          </p>
          <p>
            I'll leave you <Ch>A</Ch>lonely <Ch>D</Ch>
          </p>
          <p>
            I'll leave you <Ch>F#m</Ch>lonely
          </p>
          <p>
            I'll leave you <Ch>D</Ch>lonely
          </p>
          <p>
            I'll leave you <Ch>C#m</Ch>lonely <Ch>E</Ch>
          </p>
          <p>
            And I'll leave you <Ch>A</Ch>lonely <Ch>D</Ch>
          </p>
        </Chorus>
      </Columns>
    </Layout>
  );
}
